import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = ({ location, componentName, item, site }) => {
  // If component does not exist in acf folder, print error message
  if (!(componentName in AcfLayout)) {
    return (
      <div className="wrapper">
        {`Error: Component does not exist. `}
        {`Please create component "${componentName}.jsx" in src/acf folder `}
        {`and add export to src/acf/index.js`}
      </div>
    );
  }
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      site={site}
      {...item}
    />
  );
};

const Page = ({ data, location }) => {
  const { wordpressPost: page, site } = data;
  if (!page) return null;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = site.siteMetadata;
  return (
    <>
    <Layout location={location}>
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            site={site}
          />
        );
      })}
    </Layout>
    </>
  )
}

export default Previewable(Page, 'page');

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title,
        wordpressUrl
      }
    }
    wordpressPost: wordpressPage(id: { glob: $id }) {
      wordpress_id
      title
      content
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ... BiographyListQuery
          ... ProjectsQuery
          ... HeroQuery
          ... ReportListQuery
          ... HomeHeroQuery
          ... on WordPressAcf_IntroSection {
            id
            title
            subtitle
          }
          ... on WordPressAcf_TextBlock {
            id
            theme
            subheading
            heading
            content
            button
            buttonText
            isDownloadFile
            downloadFileLink {
              name
              url
            }
            downloadFileButtonText

          }
          ... on WordPressAcf_ImageAndText {
            id
            imagePlacement
            theme
            style
            subheading
            isSubheadingClass
            heading
            content
            button
            buttonHash
            buttonText
            image {
              source_url
            }
          }
          ... on WordPressAcf_IconsAndText {
            id
            iconsPlacement
            theme
            subheading
            heading
            content
            button
            buttonText
            panels {
              panelHeading
              panelSubheading
              icons {
                icon {
                  source_url
                }
                label
              }
            }
          }
          ... on WordPressAcf_Icons {
            id
            iconsPerRow
            theme
            icons {
              icon {
                source_url
              }
              heading
              description
            }
          }
          ... on WordPressAcf_ProjectsFeed {
            optionalText
            manuallySelectCaseStudies
            caseStudiesList {
              caseStudyItem {
                guid
                post_title
              }
            }
            customFilterLink
            customButtonText
          }
          ... on WordPressAcf_FeaturedProjectsSliderFeed {
            optionalText
            manuallySelectCaseStudies
            caseStudiesList {
              featuredCaseStudyItem {
                guid
                post_title
              }
              featuredCaseStudyItemInfo {
                title
                location
                sector
                companyName
                blurbText
              }
            }
            customFilterLink
            customButtonText
          }
          ... on WordPressAcf_Cta {
            id
            heading
            content
            button
            buttonHash
            buttonText
            backgroundImage {
              source_url
            }
          }
          ... on WordPressAcf_KeyStatisticsCounter {
            id
            statistics {
              label
              valuePrefix
              value
              valueSuffix
            }
          }
          ... on WordPressAcf_ContentWithSidebar {
            id
            sidebarLabel
            enableDisable
            sections {
              heading
              icon {
                source_url
              }
              content
              buttonOne
              buttonOneText
              buttonOneHash
              buttonTwo
              buttonTwoText
              buttonTwoHash
              contactCards {
                locationName
                streetAddress
                mailingAddress
                telephone
                fax
                googleMapsLink
              }
            }
          }
          ... on WordPressAcf_SteppedPanels {
            id
            subheading
            heading
            content
            panels {
              title
              description
              image {
                source_url
              }
            }
          }
          ... on WordPressAcf_ContactForm {
            id
            theme
            formType
            subheading
            heading
            content
          }
          ... on WordPressAcf_HomeTextContentArea {
            id
            Content {
              contentCopy
              button
              buttonText
              toggleOptions {
                toggleArrowDown
                toggleSquares
              }
              backgroundImage {
                source_url
              }
            }
          }
          ... on WordPressAcf_HomeTextAndVideoImage {
            id
            subtitle
            heading
            copyContent
            image {
              source_url
            }
            videoLink
            imageAccent
            buttonLink
            buttonText
          }
          ... on WordPressAcf_HomeThreeImage {
            id
            textContent {
              heading
              subtitle
              textContent
            }
            images {
              imageLeft {
                source_url
              }
              imageRightTop {
                source_url
              }
              imageRightBot {
                source_url
              }
            }
            backgroundImage {
              source_url
            }
          }
          ... on WordPressAcf_HomeTextAndCounter {
            id
            copyContent
            heading
            buttonText
            buttonLink
            numbers {
              metricOne {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                }
              }
              metricTwo {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                }
              }
              metricThree {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                }
              }
            }
          }
          ... on WordPressAcf_ContactUsBox {
            id
            subtitle
            heading
            buttonLink
            buttonText
            content
            image {
              source_url
            }
            headingColour
            backgroundColour
          }
        }
      }
    }
  }
`
